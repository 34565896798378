import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import Navbar from "../nav"

import "./style.scss"

const navList = [
  {
    name: "Blog",
    link: "/blog",
  },
  {
    name: "Campaigns",
    link: "/campaigns",
  },
  {
    name: "Comics",
    link: "/comics",
  },
  {
    name: "About",
    link: "/about",
  }
]

const Header = ({ siteTitle, isHomepage }) => {
  return (
    <header className={`header ` + isHomepage}>
      <div className="container">
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
          title={siteTitle}
        >
          <img className="logo" src="/images/logo.png" alt={siteTitle} />
        </Link>

        <Navbar navList={navList} />
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
