/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { globalHistory } from "@reach/router"

import Header from "../header"
import "./layout.css"
import Footer from "../footer"

const Layout = ({ children, bodyClass }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const [isHomepage, setisHomepage] = React.useState("")

  React.useEffect(() => {
    setisHomepage(globalHistory.location.pathname === '/' ? "isHomepage" : "")
    return () => {
      setisHomepage("")
    }
  }, [isHomepage])

  return (
    <div className={
      bodyClass +
      " showing-menu-modal showing-modal"
    }>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} isHomepage={isHomepage} />
      <div

      >
        <main role="main">{children}</main>
        <Footer title={data.site.siteMetadata?.title} />
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
