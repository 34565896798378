import { Link } from "gatsby"
import React from "react"
import MailChimpFormContainer from "../MailChimpFormContainer"

import "./style.scss"

const Footer = ({ title }) => {
  const footerLinks = [
    {
      title: "About AWRA",
      links: [
        {
          title: "Objectives",
          uri: "/objectives",
        },
        {
          "title": "Where We Operate",
          "uri": "/about#where-we-operate"
        },
        {
          title: "Who We Are",
          uri: "/about",
        },
        {
          "title": "Founding Women",
          "uri": "/founders/"
        },
        {
          "title": "Volunteer",
          "uri": "/volunteer/"
        },
        {
          "title": "Donations",
          "uri": "/donate/"
        },
      ],
    },
    {
      title: "Media",
      links: [
        {
          title: "Press Releases",
          uri: "/category/press-releases"
        },
        {
          title: "Contact Us",
          uri: "/contact",
        },
      ],
    },
  ]

  return (
    <footer className="footer">
      <MailChimpFormContainer />
      <div className="container">
        <nav className="links">
          {footerLinks.map((group, i) => {
            return (
              <div className="-group" key={i}>
                <p className="nav-title">{group.title}</p>
                <ul className={group.links.length < 4 ? "-list -single-column" : "-list"}>
                  {group.links.map((singleLink, i) => {
                    return (
                      <li key={i}>
                        <Link to={singleLink.uri}>{singleLink.title}</Link>
                      </li>
                    )
                  })}
                </ul>
              </div>
            )
          })}
        </nav>
        <div className="copyrights">
          <span>RSIN Number: 862265538. All rights reserved.</span>
          <span>
            © {new Date().getFullYear()}, {title} - AWRA.
          </span>
        </div>
      </div>
    </footer>
  )
}

export default Footer
