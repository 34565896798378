import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import "./style.scss"

const Navbar = ({ navList }) => {
  const [open, setOpen] = useState(false)

  const menuHandle = (e) => {
    e.preventDefault()
    setOpen(!open)
  }
  return (
    <>
      <button onClick={e => menuHandle(e)} className={open ? "hamburger hamburger--3dx is-active" : "hamburger hamburger--3dx"} type="button">
        <span className="hamburger-box">
          <span className="hamburger-inner"></span>
        </span>
      </button>
      <nav className={open ? "navigation is-open" : "navigation"}>
        <div className="links">
          {navList.map((nav, i) => {
            return (
              <Link
                className="-link"
                activeClassName="active"
                to={nav.link}
                key={i}
              >
                {nav.name}
              </Link>
            )
          })}
        </div>

        <div className="buttons">
          <Link to="/donate" className="button is-rounded is-outlined is-link">
            Donate Now
          </Link>
          <Link
            to="/volunteer"
            className="button is-rounded is-custom"
            activeClassName="active"
          >
            Volunteer
          </Link>
        </div>
      </nav>
    </>
  )
}

Navbar.propTypes = {
  navList: PropTypes.array,
}

Navbar.defaultProps = {
  navList: [],
}

export default Navbar
