import React, { useState, useEffect } from "react"
import MailchimpSubscribe from "react-mailchimp-subscribe"
import { MdCheckCircle } from "@react-icons/all-files/md/MdCheckCircle"
import InputField from "../ui/InputField"
// import PrimaryCTA from "../ui/PrimaryCTA"

import "./style.scss"

const CustomForm = ({ status, message, onValidated, success }) => {
  // const { modalOpen, setModalOpen } = useGHStContext()

  const [email, setEmail] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")

  const handleSubmit = e => {
    e.preventDefault()
    email &&
      firstName &&
      lastName &&
      email.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email,
        MERGE1: firstName,
        MERGE2: lastName,
      })
  }

  useEffect(() => {
    if (status === "success") clearFields()
    if (status === "success") {success("notification is-success")}
    
    // if (modalOpen && status === "success") clearFields()
  }, [status, success])

  const clearFields = () => {
    setFirstName("")
    setLastName("")
    setEmail("")
  }

  return (
    <form className={status === "success" ? "notification is-success mc__form" : "mc__form" } onSubmit={e => handleSubmit(e)}>
      <h3 className="mc__title">
        {status === "success"
          ? <div><MdCheckCircle size="28" color="var(--white)" /> <span>Subscribed Successfully!</span></div>
          : "Join our mailing list for future updates."}
      </h3>

      {status === "sending" && (
        <div className="mc__alert mc__alert--sending notification is-primary">Subscribing...</div>
      )}
      {status === "error" && (
        <div className="notification is-warning">
          <button class="delete"  aria-label="close warning notification."></button>
          <div
            className="mc__alert mc__alert--error"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        </div>
      )}
      {status === "success" && (
        <div
          className="mc__alert mc__alert--success"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}

      {status !== "success" ? (
        <div className="mc__field-container">
          <div className="name-inputs">
          <InputField
            // label="First Name"
            onChangeHandler={setFirstName}
            type="text"
            value={firstName}
            placeholder="First Name"
            isRequired
          />

          <InputField
            // label="Last Name"
            onChangeHandler={setLastName}
            type="text"
            value={lastName}
            placeholder="Last Name"
            isRequired
          />
          </div>

         <div className="email-input">
         <InputField
            // label="Email"
            onChangeHandler={setEmail}
            type="email"
            value={email}
            placeholder="Your Email"
            isRequired
          />
         </div>
        </div>
      ) : null}

      {/*Close button appears if form was successfully sent*/}
      {status === "success" ? (
        null
      ) : (
        <InputField
          label="Subscribe"
          type="submit"
          formValues={[email, firstName, lastName]}
          className={status === "sending" ? "is-loading" : ""}
          disable={status === "sending" ? true : false}
        />
      )}
    </form>
  )
}

const MailchimpFormContainer = props => {
  const postUrl = `https://azazy.us5.list-manage.com/subscribe/post?u=${process.env.GATSBY_MAILCHIMP_U}&id=${process.env.GATSBY_MAILCHIMP_ID}`
  const [success, setSuccess] = useState("")
  return (
    <div className={"mc__form-container " + success}>
      <MailchimpSubscribe
        url={postUrl}
        render={({ subscribe, status, message }) => (
          <CustomForm
            status={status}
            message={message}
            onValidated={formData => subscribe(formData)}
            success={setSuccess}
          />
        )}
      />
    </div>
  )
}

export default MailchimpFormContainer
